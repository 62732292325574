/* eslint-disable eqeqeq */

<template>
  <div class="home_new_box" id="boxFixed">
    <div class="boxs">
      <div class="diqu">地区：</div>
      <div class="box">
        <span v-for="(item, index) in cityList" :key="index" class="box_item" @click="active(item.id, index)" :class="[indexs === index ? 'active' : '']">{{ item.name }}</span>
      </div>
    </div>
    <div class="boxs">
      <div class="diqu">仓库：</div>
      <div class="box">
        <span v-for="(item, index) in loadDeliverByCityList" :key="index" class="box_item" @click="active1(item, index)" :class="[index1 === index ? 'active' : '']">{{ item.name }}</span>
      </div>
    </div>
    <div class="boxs">
      <div class="diqu">排序筛选：</div>
      <div style="display: flex; align-items: center; flex: 1; margin-left: 30px">
        <!-- <div class="x1"> -->
        <!-- <p @click="jiageBtn2" :class="[jiage == 2 ? 'jiageActive' : '']">默认</p> -->
        <!-- </div> -->
        <div class="x1">
          <p @click="jiageBtn" :class="[jiage == 0 ? 'jiageActive' : '']">价格排序</p>
          <div class="sans">
            <span class="san" :class="[spans === 0 ? 'sanActive' : '']"></span>
            <span class="san1" :class="[spans === 1 ? 'sanActive1' : '']"></span>
          </div>
        </div>
        <div class="x1">
          <p @click="jiageBtn1" :class="[jiage == 1 ? 'jiageActive' : '']">重量排序</p>
          <div class="sans">
            <span class="san" :class="[spans === 3 ? 'sanActive' : '']"></span>
            <span class="san1" :class="[spans === 4 ? 'sanActive1' : '']"></span>
          </div>
        </div>
        <!-- <div class="x1"> -->
        <!-- <p @click="jiageBtn3" :class="[jiage == 3 ? 'jiageActive' : '']">销量排序</p> -->
        <!-- </div> -->
        <div class="x1">
          <el-dropdown trigger="click" @command="getValue1">
            <span class="el-dropdown-link">{{ pingtai }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown" style="width: 80px; text-align: center; background-color: #fff">
              <el-dropdown-item v-for="(item, index) in options1" :key="index" style="padding: 0" :command="item">{{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="x1">
          <el-dropdown trigger="click" @command="getValue">
            <span class="el-dropdown-link">{{ peifu }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown" style="width: 160px; background-color: #fff">
              <el-dropdown-item v-for="(item, index) in options" :key="index" :command="item">{{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- <el-card class="notice">
      <el-carousel indicator-position="none" arrow="never" direction="vertical" :interval="500">
        <el-carousel-item v-for="i in 4" :key="i" class="notice_item"> 此处是公告内容！此处是公告内容！{{ i }} </el-carousel-item>
      </el-carousel>
    </el-card> -->
  </div>
</template>
<script>
import { getAllCity, loadDeliverByCity } from '../../../../network/api'
export default {
  created() {
    getAllCity().then((res) => {
      if (res.code === 1) {
        this.cityList.push(...res.data)
        loadDeliverByCity().then((res) => {
          if (res.code === 1) {
            this.loadDeliverByCityList.push(...res.data)
          }
        })
      }
    })
  },
  data() {
    return {
      spans: '',
      loadDeliverByCityList: [
        {
          name: '全部'
        }
      ],
      cityList: [
        {
          name: '全部'
        }
      ],
      isFixed: false,
      offsetTop: 0,
      pingtai: '平台',
      peifu: '专区',
      list: [],
      indexs: 0,
      index1: 0,
      jiage: 2,
      value: '',
      params: {
        deliver: '',
        keywords: '',
        storage_id: '',
        deliver_tag: '',
        city_id: '',
        goods_class: '0',
        goods_type: 'id',
        op: 'daifa',
        platform: ''
      },
      options: [
        {
          value: '全部',
          label: 0
        },
        {
          value: '一毛专区',
          label: 1
        },
        {
          value: '性价比专区',
          label: 2
        },
        {
          value: '洗护专区',
          label: 3
        }
      ],
      options1: [
        {
          value: '全部',
          label: 0
        },
        {
          value: '淘宝',
          label: 'tb_switch'
        },
        {
          value: '阿里',
          label: 'ali_switch'
        },
        {
          value: '多多',
          label: 'pinxixi_switch'
        },
        {
          value: '抖音',
          label: 'dy_switch'
        },
        {
          value: '京东',
          label: 'jd_switch'
        },
        {
          value: '其他',
          label: 'others_switch'
        }
      ],
      platform: 0
    }
  },
  computed: {},
  methods: {
    getValue1(item) {
      this.pingtai = item.value
      this.params.platform = item.label
      this.params.storage_id = ''
      this.index1 = 0
      this.$emit('newList', this.params)
    },

    getValue(e) {
      this.params.goods_class = e.label
      this.$emit('newList', this.params)
      this.peifu = e.value
    },
    active(id, index) {
      this.indexs = index
      if (index === 0) {
        this.params.city_id = ''
        if (index === 0) {
          this.params.deliver = ''
          this.params.deliver_tag = ''
          this.index1 = 0
        }
      } else {
        this.params.city_id = id
        this.index1 = 0
        this.params.deliver = ''
        this.params.deliver_tag = ''
      }
      // if (this.platform !== 0) {
      //   this.platform = 0
      //   this.params.storage_id = ''
      //   this.pingtai = '平台'
      // }
      loadDeliverByCity({
        city_id: id
      }).then((res) => {
        if (res.code === 1) {
          this.loadDeliverByCityList = [
            {
              name: '全部'
            }
          ]
          this.loadDeliverByCityList.push(...res.data)
          this.$emit('newList', this.params)
        }
      })
    },
    active1(item, index) {
      // if (this.platform === 0) {
      this.index1 = index
      this.params.deliver = item.name
      this.params.deliver_tag = item.name
      this.$emit('newList', this.params)
      // } else {
      //   this.index1 = index
      //   this.params.storage_id = item.id
      //   this.$emit('newList', this.params)
      // }
    },
    jiageBtn() {
      this.jiage = 0
      this.spans = this.spans === 0 ? 1 : 0
      this.params.goods_type = 'goods_price'
      // eslint-disable-next-line eqeqeq
      if (this.spans == 1) {
        this.$emit('sortShop')
      } else {
        this.$emit('newList', this.params)
      }
    },
    jiageBtn1() {
      this.jiage = 1
      this.spans = this.spans === 3 ? 4 : 3
      this.params.goods_type = 'goods_weight'
      this.$emit('newList', this.params)
    },
    jiageBtn2() {
      this.jiage = 2
      this.params.goods_type = 'id'
      this.$emit('newList', this.params)
    },
    jiageBtn3() {
      this.jiage = 3
      this.params.goods_type = 'goods_num'
      this.$emit('newList', this.params)
    }
  }
  // eslint-disable-next-line no-dupe-keys
}
</script>
<style scoped lang="scss">
.notice >>> .el-card__body {
  padding: 0;
  height: 100%;
}
.notice >>> .el-carousel {
  height: 100%;
}
.notice >>> .el-carousel__container {
  height: 100%;
}
.home_new_box >>> .is-always-shadow {
  box-shadow: none;
  border-bottom: 1px dotted #d2d2d2;
}
.notice >>> .el-carousel--vertical {
  overflow-y: initial;
}
</style>
<style lang="scss" scoped>
/**/
* {
  font-size: 12px !important;
}
.notice {
  max-width: 1200px;
  height: 50px !important;
  // left: 50%;
  // transform: translate(-50%);
  // position: absolute;
  // z-index: 2;
  // padding: 0;
  // top: 200px;
  background-color: red;
}

/*使文字和公告图片在一行*/
.notice_item {
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: red;
  padding: 0 20px;
}
.notice_item img {
  width: 40px;
  /*height: 16px;*/
  margin: 0 44px 0 32px;
}
* {
  font-size: 16px;
}
.small {
  width: 200px;
  background-color: red;
  height: 24px;
}
.home_new_box {
  box-sizing: border-box;
  width: 1200px;
  background: #fff;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 20px;
  z-index: 11;
  font-size: 12px;
  position: sticky;
  top: 0;
  border-radius: 6px;
  .boxs {
    display: flex;
    justify-content: flex-start;
    padding: 6px 24px;
    border-bottom: 1px dotted #d2d2d2;
    // align-items: center;
    .diqu {
      width: 80px;
      flex-shrink: 0;
    }
    .box {
      flex: 1;
      margin-left: 30px;
      .box_item {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        margin: 0 6px 0px 14px;
        padding: 0 6px;
        cursor: pointer;
      }
    }
  }
}
.active {
  box-sizing: content-box;
  background: #3af;
  color: #fff;
  border-radius: 3px;
  // padding: 2px !important;
  padding: 2px 4px 2px 4px !important;
}
.jiageActive {
  color: #3af;
  font-weight: 600;
}
.x1 {
  //   font-size: 12px;
  color: #333;
  margin-right: 30px;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
.san {
  position: absolute;
  right: 0;
  top: 0px;
  display: block;
  border: 6px solid;
  border-color: transparent transparent rgb(216, 216, 216) transparent;
  //   border-bottom: ;
}
.sans {
  position: absolute;
  top: -3px;
  right: 4px;
}
.san1 {
  position: absolute;
  right: 0;
  top: 14px;
  // right: -8px;
  display: block;
  border: 6px solid;
  border-color: rgb(216, 216, 216) transparent transparent transparent;
}
.sanActive {
  border: 7px solid;
  top: -1px;
  right: -1px;
  border-color: transparent transparent rgb(51, 170, 255) transparent;
}
.sanActive1 {
  border: 7px solid;
  //   top: -8px;
  right: -1px;
  border-color: rgb(51, 170, 255) transparent transparent transparent;
}
</style>
