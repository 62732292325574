<template>
  <div ref="div">
    <!-- infinite-scroll-distance="100" infinite-scroll-immediate="false" v-infinite-scroll="load" -->
    <div class="iframepage" ref="iframepage">
      <nav-bar></nav-bar>
      <IndexBanner></IndexBanner>
      <nav-list @newList="newList" @sortShop="sortShop"></nav-list>
      <shoop-list ref="son" @so="so" @goLog="goLog"></shoop-list>
      <back-top></back-top>
      <!-- <bottom-footer /> -->
      <goods-dialog ref="sons"></goods-dialog>
    </div>
  </div>
</template>
<script>
// import NavBar from './newViews/navBar'
import NavBar from '../../components/navbar/NavBar.vue'
import BackTop from '../../components/backtop/BackTop.vue'
import IndexBanner from './newViews/HomeHeader/index.vue'
import NavList from './newViews/NavList'
import ShoopList from './newViews/ShoopList'
import goodsDialog from '@/components/goodsDialog.vue'
// import BackTop from './newViews/BacTop'
// import BottomFooter from 'components/bottomfooter/BottomFooter'
export default {
  components: {
    IndexBanner,
    goodsDialog,
    NavList,
    ShoopList,
    NavBar,
    BackTop
    // BottomFooter
  },
  data () {
    return {
      list: '',
      count: 100,
      busy: false,
      // clientHeight: ''
      scrollTop: 1
    }
  },
  mounted () {
    window.addEventListener('scroll', this.Scrollbottom, true)
    this.scrollTop = 1
  },
  created () {
    this.so()
  },
  methods: {
    goLog (item) {
      this.$refs.sons.dialogShow(item)
    },
    so () {
      // 滚动条回到顶部
      window.onbeforeunload = function () {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      }
    },
    shuchu () {
      // 滚动条回到顶部，需要负
      document.documentElement.scrollTop = -10
    },
    newList (params) {
      // 兄弟调用方法使用父亲作为中间件
      this.$refs.son.newList(params)
    },
    sortShop () {
      console.log('倒叙')
      // this.$refs.son.reverseShop()
    },
    Scrollbottom () {
      //  监听滚动条触底
      // eslint-disable-next-line eqeqeq
      if (this.scrollTop == 30) return
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight) {
        // eslint-disable-next-line eqeqeq
        if (this.$route.path == '/goods') { this.$refs.son.addShop() }
      }
    }
  },

  beforeDestroy () {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener('scroll', this.Scrollbottom)
  }
}
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.warp {
  height: 100%;
  overflow-y: scroll;
}
.fiex {
  position: sticky;
}
div {
  min-width: 1200px;
  background-color: #f5f5f5;
  box-sizing: border-box;
}
.iframepage {
  // max-height: 100vh;
  // overflow: auto;
  border: 1px solid #ddd;
  padding: 0 16px;
}
// body {
//   overflow: hidden;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0;
// }
</style>
