<template>
  <div class="ant-carousel">
    <div class="container-full banner-content">
      <el-carousel class="carousel" height="260px" arrow="hover" :interval="5000" trigger="click">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img style="width: 100%; background-repeat: repeat; height: 260px" :src="item.localimg" @click="bannerClick(index)" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { getAdsInfo } from 'network/api'

export default {
  data() {
    return {
      bannerList: [],
      link: ''
    }
  },
  created() {
    getAdsInfo({ adstag: 'shop' }).then((res) => {
      this.bannerList = res.data.adslist
      this.link = res.data.adslist
    })
  },
  methods: {
    bannerClick(index) {
      window.open(this.link[index].clickhref, '_blank')
    }
    // switch (index) {
    //   case 0:
    //     window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    //     break
    //   case 1:
    //     this.$router.push('/join')
    //     break
    //   case 2:
    //     this.$router.push('/account/InviteFriends')
    //     break
    // }
    // }
  }
}
</script>

<style scoped lang="scss">
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
}
img {
  height: 200px;
}
</style>
